.filter-status-checkbox-area {
  display: flex;
  align-items: flex-end;
}

.filter-status-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding-right: 4px;
  user-select: none;
}

.filter-status-checkbox + .filter-status-checkbox {
  margin-left: 19px;
}

.filter-status-checkbox-on {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: rgb(155, 215, 42);
  cursor: pointer;
}

.filter-status-checkbox-off {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

.filter-status-checkbox span {
  height: 100%;
  font-style: normal;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.5px;
  font-size: 11.5px;
  margin-left: 4px;
}

.gradeHeader {
  position: relative;
}